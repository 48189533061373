<template>
  <div class="ma-4">
    <v-row>
      <v-col>
        <span class="font-weight-black subtitle-1">STATUS</span>
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12" sm="7" md="7">
        <v-hover v-slot="{hover }" open-delay="200">
          <v-card  class="top-gradient-teal" :elevation="hover ? 16 : 2">
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Validasi</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="teal"
                              value="75"
                          >
                            {{  75 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Approved</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="orange"
                              value="60"
                          >
                            {{  60 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="mb-4">
                      <div class="mb-4">Terkirim ke SIASN</div>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <v-progress-circular
                              rotate="360"
                              size="100"
                              width="15"
                              color="primary"
                              value="90"
                          >
                            {{  90 }}%
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
      </v-col>
    </v-row>

  </div>
</template>

<script>
export default {
  name: "ChartBasic",
  components: {
  }

}
</script>

<style scoped>

</style>